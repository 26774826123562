import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import Template from "../../components/Template";
import Button from "components/Atoms/Button";

import Image from "next/image";
import ZespolPhoto from "../../../../assets/images/zespol.jpeg";
import theme from "theme";
import { useTranslation } from "next-i18next";
import DatePicker from "components/Atoms/DatePicker";

import CustomTimePicker from "components/Atoms/CustomTimePicker";
import { Controller, useForm } from "react-hook-form";
import { useSnackbar } from "hooks/useSnackbar";
import { formDataConvert, validForm } from "utils/formUtils";
import InterestService from "services/InterestService";
import { CheckCircle, Error } from "@mui/icons-material";

const IMGComponent = () => (
  <Box
    flexBasis={{
      xs: 300,
      md: "48%",
    }}
    position="relative"
    borderRadius={4}
    overflow="hidden"
    mb={{ xs: 2, md: 0 }}
    sx={{
      [theme.breakpoints.down("md")]: {
        height: 300,
      },
    }}
  >
    <Image
      alt="Mountains"
      src={ZespolPhoto}
      fill={true}
      sizes="100%"
      style={{
        objectFit: "cover",
      }}
    />
  </Box>
);

const FormContact = () => {
  const { t } = useTranslation("home");
  const { t: tCommon } = useTranslation();

  const {
    handleSubmit,
    control,
    register,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const { openSnackBar } = useSnackbar();

  const onSubmit = async (data, e) => {
    const valid = validForm(data, ["email"]);

    if (!valid.valid) {
      setError("email", { type: "custom", message: "Mail validator" });

      return openSnackBar(
        `${tCommon("Please fill in the required fields")}: "E-mail"`,
        <Error color="secondary" />
      );
    }

    try {
      const convertData = formDataConvert(data);

      const res = await InterestService.create(convertData);
      if (res.status === 200) {
        openSnackBar(
          tCommon("Message has been sent"),
          <CheckCircle color="success" />
        );
      } else {
        openSnackBar(
          tCommon("An error occurred while sending the message"),
          <Error color="secondary" />
        );
      }
    } catch (error) {
      console.log({ error });
      openSnackBar(
        tCommon("An error occurred while sending the message"),
        <Error color="secondary" />
      );
    }
  };

  return (
    <Template
      title={t("Meet our team.title")}
      subTitle={t("Meet our team.desc")}
      text={t("We are here for you...")}
      id="contact"
    >
      <Box
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        px={{ xs: 2, lg: 0 }}
        my={{ xs: 4, lg: 0 }}
        justifyContent="space-between"
      >
        <IMGComponent />

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          display="flex"
          flexDirection="column"
          rowGap={4}
          flexBasis="48%"
        >
          <Typography fontSize={24} fontWeight={600}>
            {t("Contact us")}
          </Typography>
          <Box display="flex" columnGap={4}>
            <TextField
              fullWidth
              label={tCommon("First name")}
              {...register("name")}
            />
            <TextField
              fullWidth
              label={tCommon("Last name")}
              {...register("surname")}
            />
          </Box>
          <Box display="flex" columnGap={4}>
            <TextField
              fullWidth
              error={errors?.email ? true : false}
              label={`${tCommon("E-mail")} *`}
              type="email"
              {...register("email", { onChange: () => clearErrors("email") })}
            />

            <TextField
              fullWidth
              label={tCommon("Topic")}
              {...register("subject")}
            />
          </Box>
          <TextField
            label={tCommon("Message")}
            multiline
            rows={6}
            {...register("message")}
          />
          <Typography fontWeight={600} my={-2}>
            {tCommon("Suggest a meeting date")}
          </Typography>
          <Box display="flex" columnGap={4}>
            <Controller
              control={control}
              name="meetingDate"
              render={({ field: { onChange, value } }) => (
                <DatePicker disablePast value={value} onChange={onChange} />
              )}
            />
            <Controller
              control={control}
              name="meetingDate"
              render={({ field: { onChange, value } }) => (
                <CustomTimePicker value={value} onChange={onChange} />
              )}
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <FormControlLabel
              control={<Checkbox />}
              label={tCommon("formContactAccept.personal data")}
              {...register("acceptProcessingOfPersonalData")}
            />
            <FormControlLabel
              control={<Checkbox />}
              label={tCommon("formContactAccept.commercial information")}
              {...register("acceptReceivingCommercialInformation")}
            />
          </Box>
          <Box display="flex" columnGap={4}>
            <Button
              formNoValidate
              type="submit"
              sx={{ flexBasis: `calc(50% - 10px)` }}
            >
              {t("Send")}
            </Button>
            <Box></Box>
          </Box>
        </Box>
      </Box>
    </Template>
  );
};

export default FormContact;
