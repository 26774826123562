import axios from "../utils/axios";

const InterestService = {
  create: async (data) => {
    return await axios.post("/interests", data, {
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  },
};

export default InterestService;
