import dayjs from "dayjs";
import { regexEmailTest } from "utils/regex";

const acceptElements = {
  acceptProcessingOfPersonalData:
    "Zgoda na przetwarzanie danych osobowych przez MVGM Property Management Poland Sp. z o.o.",

  acceptReceivingCommercialInformation:
    "Zgoda na otrzymywanie informacji handlowej od MVGM Property Management Poland Sp. z o.o.",
};

const dateFormat = "YYYY-MM-DD HH:mm:ss";

export const formDataConvert = (data) => {
  const newData = {};
  for (const [key, value] of Object.entries(data)) {
    if (value && acceptElements?.[key]) {
      newData[key] = acceptElements?.[key];
    } else if (
      key === "meetingDate" &&
      dayjs(value).format(dateFormat) !== "Invalid Date"
    ) {
      newData[key] = dayjs(value).format(dateFormat);
    } else if (value) {
      newData[key] = value;
    }
  }

  const joinMessage = [
    { label: "Temat", value: newData?.subject },
    { label: "Wiadomość", value: newData?.message },
    { label: "Zaproponowana data spotkania", value: newData?.meetingDate },
    { label: "Numer lokalu", value: newData?.realestateName },
  ]
    ?.filter((el) => el?.value)
    ?.map((el) => {
      return `${el?.label}: 
${el?.value}
`;
    })
    ?.join("\r\n");

  return { ...newData, message: joinMessage };
};

export const validForm = (data, requiredFields = []) => {
  const errors = {};
  let valid = true;

  for (const [key, value] of Object.entries(data)) {
    if (requiredFields.includes(key) && !value) {
      errors[key] = "This is required field.";
    }

    if (key === "email" && value !== "" && !regexEmailTest(value)) {
      errors[key] = "This is not valid email.";
    }

    if (errors[key]) {
      valid = false;
    }
  }

  return { valid, errors };
};
