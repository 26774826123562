import { useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { plPL, enUS } from "@mui/x-date-pickers/locales";
import "dayjs/locale/pl";
import "dayjs/locale/en";
import { Box, IconButton, Typography } from "@mui/material";
import { Event } from "@mui/icons-material";

const BrowserField = (props) => {
  const {
    id,
    ref,
    label,
    InputProps: { ref: containerRef, startAdornment, endAdornment } = {},
    inputProps,
    value,
    placeholder,
    onClick,
    error,
    focused,
    ...other
  } = props;

  const defaultValue =
    !value || value === "" || value === "DD.MM.YYYY" ? true : false;

  return (
    <Box
      id={id}
      ref={containerRef ?? ref}
      onClick={onClick}
      display="flex"
      alignItems="center"
      width="100%"
      border="1px solid #ddeff5"
      borderRadius={2}
      overflow="hidden"
      sx={{
        cursor: "pointer",
        transition: "0.3s ease",
        ":hover": {
          borderColor: "#cae6ef",
          boxShadow: "0 0 6px 1px #cae6ef",
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="100%"
      >
        <Box
          backgroundColor="white"
          width="100%"
          height="100%"
          py={3}
          px={2}
          display="flex"
          alignItems="center"
        >
          <Typography
            {...inputProps}
            {...other}
            sx={{
              opacity: defaultValue ? 0.75 : 1,
            }}
          >
            {defaultValue ? placeholder : value}
          </Typography>
        </Box>
        <Box px={2}>
          <IconButton aria-label="select">
            <Event
              sx={{
                fill: "rgba(0, 0, 0, 0.54)",
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

const DatePicker = ({
  value,
  onChange = () => {},
  format = "DD.MM.YYYY",
  disablePast = false,
}) => {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const { t: tCommon } = useTranslation("common");

  const getLocale = () => {
    const locale = router.locale;
    switch (locale) {
      case "pl":
        return "pl";
      case "en":
        return "en";
      default:
        return "pl";
    }
  };

  const getLocaleText = () => {
    const locale = router.locale;
    switch (locale) {
      case "pl":
        return plPL.components.MuiLocalizationProvider.defaultProps.localeText;
      case "en":
        return enUS.components.MuiLocalizationProvider.defaultProps.localeText;
      default:
        return plPL.components.MuiLocalizationProvider.defaultProps.localeText;
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={getLocale()}
      localeText={getLocaleText()}
    >
      <MuiDatePicker
        sx={{ width: "100%" }}
        open={open}
        onClose={handleClose}
        format={format}
        value={value ? value : null}
        onChange={onChange}
        disablePast={disablePast}
        slotProps={{
          textField: {
            placeholder: tCommon("Date"),
            onClick: () => {
              setOpen(true);
            },
            readOnly: true,
            sx: {
              width: "100%",
              "& .MuiInputBase-root": {
                "& .MuiInputBase-input": {
                  cursor: "pointer",
                },
              },
            },
          },
        }}
        slots={{ textField: BrowserField }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
