import React, { useEffect, useMemo, useState } from "react";

import { components } from "react-select";
import { IconButton } from "@mui/material";
import { Schedule } from "@mui/icons-material";

import { useTranslation } from "next-i18next";

import Select from "components/Modules/Select";

import dayjs from "dayjs";

const CustomTimePicker = ({
  value = null,
  onChange = () => {},
  id = "CustomTimePicker",
  ...props
}) => {
  const { t: tCommon } = useTranslation("common");

  useEffect(() => {
    if (!value) return;
    if (dayjs(value).format("HH:mm") === "00:00") {
      const newDate = dayjs(value).set("hour", 7).set("minute", 0);
      onChange(newDate);
    }
  }, [value]);

  const getCurrentTime = useMemo(() => {
    if (!value) return null;
    return dayjs(value).format("HH:mm");
  }, [value]);

  const handeChange = (e) => {
    const [hour, minute] = e?.split(":");
    if (!value) {
      const newDate = dayjs().set("hour", hour).set("minute", minute);
      onChange(newDate);
      return;
    } else {
      const newDate = dayjs(value).set("hour", hour).set("minute", minute);
      onChange(newDate);
    }
  };

  function generateTimeOptions({ start, end }) {
    const timeOptions = [];
    const [startHour, startMinute] = start;
    const [endHour, endMinute] = end;

    let currentTime = startHour * 60 + startMinute;
    const endTime = endHour * 60 + endMinute;

    while (currentTime <= endTime) {
      const hour = Math.floor(currentTime / 60);
      const minute = currentTime % 60;

      const hourStr = hour < 10 ? `0${hour}` : hour;
      const minuteStr = minute < 10 ? `0${minute}` : minute;

      timeOptions.push({
        value: `${hourStr}:${minuteStr}`,
        label: `${hourStr}:${minuteStr}`,
      });

      currentTime += 10;
    }

    return timeOptions;
  }

  const options = {
    start: [7, 0],
    end: [22, 0],
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <IconButton aria-label="select">
          <Schedule
            sx={{
              fill: "rgba(0, 0, 0, 0.54)",
            }}
          />
        </IconButton>
      </components.DropdownIndicator>
    );
  };

  const Input = (props) => {
    return (
      <components.Input {...props} isHidden inputMode="none"></components.Input>
    );
  };

  return (
    <Select
      id={id}
      options={generateTimeOptions(options)}
      placeholder={tCommon("Time")}
      icon={DropdownIndicator}
      input={Input}
      // placeholderComponent={Placeholder}
      value={getCurrentTime}
      onChange={handeChange}
      width="100%"
      styleOptions={{
        dropdownIndicator: {
          borderRadius: 50,
          marginRight: 6,
          marginLeft: 6,
          padding: 0,

          ":hover": {
            backgroundColor: null,
            color: null,
          },
        },
      }}
      {...props}
    />
  );
};

export default CustomTimePicker;
